import React from 'react'
import PageLayout from '../components/pagelayout'
import { Typography } from '@material-ui/core'

export default function NotFound(){
    return(
<PageLayout>
<Typography variant="h3" color="error">Route you are looking for does not exist :( Oh the sadness</Typography>
</PageLayout>
    )
}